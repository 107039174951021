<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    @click:outside="handleClickOutSite"
    :persistent="true"
    ref="dialog"
    content-class="modal-fullscreen width-100pc"
    transition="dialog-bottom-transition"
  >
    <div class="content-main">
      <div class="head-category-popup">
        <span class="log-confirm_text mr-auto">{{ $t("list_menu.title_category_selection") }}</span>
        <div class="list-emission-close" @click="close">
          <img
            class="mr-0 close"
            src="@/assets/images/dialog/close.svg"
          />
          <img
            class="mr-0 close-active"
            src="@/assets/images/dialog/close_active.svg"
          />
          <div class="list-emission-close__text">{{ $t("list_menu.button_close") }}</div>
        </div>
      </div>
      <div class="emissions-page">
        <div class="page-header">{{ $t("list_menu.description_text_list_scope") }}</div>
        <div class="summary">
          <div class="summary-area">
            <div class="summary-area__scope">
              <div class="summary-area__scope-left" :class="!isEnableScope3 && 'disabled'">
                <scope-component :scopes="leftScopeContainer" :header="header[0]" @selectedScope="selectedScope" :scopeCategoriesList="scopeCategoriesList" />
              </div>
              <div class="arrow mobile"><img src="@/assets/images/emissions/arrow-down.svg" alt="" /></div>
              <div class="arrow desktop"><img src="@/assets/images/emissions/arrow.svg" alt="" /></div>
              <div class="summary-area__scope-middle">
                <scope-component :scopes="middleScope1Container" :isCategory="false" :header="header[1]"
                                 @selectedScope="selectedScope" :scopeCategoriesList="scopeCategoriesList" />
                <scope-component :scopes="middleScope2Container" :isCategory="false" :header="header[1]"
                                 @selectedScope="selectedScope" :scopeCategoriesList="scopeCategoriesList" />
              </div>
              <div class="arrow mobile"><img src="@/assets/images/emissions/arrow-down.svg" alt="" /></div>
              <div class="arrow desktop"><img src="@/assets/images/emissions/arrow.svg" alt="" /></div>
              <div class="summary-area__scope-right" :class="!isEnableScope3 && 'disabled'">
                <scope-component :scopes="rightScopeContainer" :header="header[2]" @selectedScope="selectedScope" :scopeCategoriesList="scopeCategoriesList" />
              </div>
            </div>
            <div class="summary-area__scope">
              <scope-component :scopes="bottomScopeContainer" :header="header[3]" @selectedScope="selectedScope" :scopeCategoriesList="scopeCategoriesList" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { getScopesAndCategories } from "@/api/emissionCalculations";
import { mapActions, mapState } from "vuex";
import ScopeComponent from "@/components/emissionScope/Scope-Category.vue";

export default {
  components: { ScopeComponent },
  props     : {
    dialog       : {
      type   : Boolean,
      default: true
    },
    method       : {
      type   : Number,
      default: null
    },
    currentSelect: {
      type   : Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    scopeCategoriesList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      items                : [
        {
          text    : "ダッシュボード",
          disabled: true,
          href    : "/"
        }
      ],
      itemSelect           : null,
      // header               : ["上流", "自社", "下流", "その他"],
      leftScopeContainer   : {},
      middleScope1Container: {},
      middleScope2Container: {},
      rightScopeContainer  : {},
      bottomScopeContainer : {},
      focusElement         : null,
      isEnableScope3       : false
    };
  },
  mounted() {
    this.getScopeStatus();
  },
  watch  : {
    windowWidth: {
      handler() {
        this.windowWidth = window.innerWidth;
      }
    }
  },
  methods: {
    ...mapActions("emission", ["selectDuration", "setScopes"]),
    ...mapActions("registerData", ["updateDuration", "updateListDuration"]),
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    selectedScope(getScopesAndCategories, categoryName) {
      this.$emit("selectedScope", getScopesAndCategories, categoryName);
    },
    async getScopeStatus() {
      await getScopesAndCategories({ contractor_id: this.$store.state.userData.contractor })
        .then((response) => {
          this.updateContainer(response);
          let isEnableScope3 = response.find((object) => {
            return object.hasOwnProperty('display_scope_3')
          })
          this.isEnableScope3 = isEnableScope3.display_scope_3
          this.$store.dispatch('dashboard/setIsEnableScope3', isEnableScope3.display_scope_3)

        })
        .catch(() => {
        });
    },
    updateContainer(scopes) {
      const leftScope3           = scopes[2].categories.filter((category) => category.id < 9);
      const rightScope3          = scopes[2].categories.filter((category) => category.id > 8 && category.id < 16);
      const listTitleMiddle = [
        this.$t('register_data.title_scope_1_category_1'),
        this.$t('register_data.title_scope_2_category_1'),
      ]
      const listTitleLang = [
        this.$t('register_data.title_scope_3_category_1'),
        this.$t('register_data.title_scope_3_category_2'),
        this.$t('register_data.title_scope_3_category_3'),
        this.$t('register_data.title_scope_3_category_4'),
        this.$t('register_data.title_scope_3_category_5'),
        this.$t('register_data.title_scope_3_category_6'),
        this.$t('register_data.title_scope_3_category_7'),
        this.$t('register_data.title_scope_3_category_8'),
        this.$t('register_data.title_scope_3_category_9'),
        this.$t('register_data.title_scope_3_category_10'),
        this.$t('register_data.title_scope_3_category_11_export'),
        this.$t('register_data.title_scope_3_category_12_export'),
        this.$t('register_data.title_scope_3_category_13'),
        this.$t('register_data.title_scope_3_category_14'),
        this.$t('register_data.title_scope_3_category_15'),
        this.$t('register_data.title_scope_3_category_16'),
      ]
      const listTitleBottom = [
        this.$t('list_menu.button_emissions_others'),
        this.$t('list_menu.button_emissions_reduction_certificed'),
      ]
      this.middleScope1Container = {
        scope     : "1",
        scope_icon: "scope1.svg",
        categories: [
          {
            id     : "1",
            name   : scopes[0].description,
            status : scopes[0].status,
            tooltip: this.tooltipContent()[0],
          }
        ]
      };
      
      this.middleScope2Container = {
        scope     : "2",
        scope_icon: "scope2.svg",
        categories: [
          {
            id     : "1",
            name   : scopes[1].description,
            status : scopes[1].status,
            tooltip: this.tooltipContent()[1],
          }
        ]
      };
      this.bottomScopeContainer  = {
        scope     : "4",
        scope_icon: "",
        categories: [
          {
            id     : scopes[2].categories[15].id,
            name   : scopes[2].categories[15].name,
            status : scopes[2].categories[15].status,
            tooltip: this.tooltipContent()[17],
            disabled: this.disabled
          },
          {
            id     : "1",
            name   : scopes[3].categories[0].name,
            status : scopes[3].categories[0].status,
            tooltip: this.tooltipContent()[18],
            disabled: this.disabled
          }
        ]
      };
      this.leftScopeContainer    = {
        scope     : "3",
        scope_icon: "scope3.svg",
        categories: this.formatScopeData(leftScope3),
        align     : "left"
      };
      this.rightScopeContainer   = {
        scope     : "3",
        scope_icon: "scope3.svg",
        categories: this.formatScopeData(rightScope3),
        align     : "right"
      };
    },
    formatScopeData(data, listTitleLang) {
      let formatData = [];
      data.map((category, index) => {
        formatData.push({
          id     : category.id,
          name   : category.name,
          status : category.status,
          tooltip: this.tooltipContent()[category.id + 1],
          disabled: [2, 8, 16].includes(category.id) ? this.disabled : false
        });
      });
      return formatData;
    },
    tooltipContent() {
      return [
        this.$t('list_menu.tooltip_scope_1'),
        this.$t('list_menu.tooltip_scope_2'),
        this.$t('list_menu.tooltip_scope_3_category_1'),
        this.$t('list_menu.tooltip_scope_3_category_2'),
        this.$t('list_menu.tooltip_scope_3_category_3'),
        this.$t('list_menu.tooltip_scope_3_category_4'),
        this.$t('list_menu.tooltip_scope_3_category_5'),
        this.$t('list_menu.tooltip_scope_3_category_6'),
        this.$t('list_menu.tooltip_scope_3_category_7'),
        this.$t('list_menu.tooltip_scope_3_category_8'),
        this.$t('list_menu.tooltip_scope_3_category_9'),
        this.$t('list_menu.tooltip_scope_3_category_10'),
        this.$t('list_menu.tooltip_scope_3_category_11'),
        this.$t('list_menu.tooltip_scope_3_category_12'),
        this.$t('list_menu.tooltip_scope_3_category_13'),
        this.$t('list_menu.tooltip_scope_3_category_14'),
        this.$t('list_menu.tooltip_scope_3_category_15'),
        this.$t('list_menu.tooltip_scope_3_category_16'),
        this.$t('list_menu.tooltip_scope_4_category_1')
      ];
    },
    addTooltip(event) {
      this.focusElement = event.target.parentElement;
      this.focusElement.classList.add("tooltip-icon");
    },
    removeTooltip() {
      if (this.focusElement) {
        this.focusElement.classList.remove("tooltip-icon");
      }
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("question.svg");
      } else {
        event.target.src = this.getSettingIcon("question-active.svg");
      }
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/images/emissions/status/${image}`);
      }
      return "";
    },
    close() {
      this.$emit("close");
      this.itemSelect = null;
    },
    submit() {
      this.$emit("submit", this.itemSelect);
      this.itemSelect = null;
    },
    handleClickOutSite() {
      this.$emit("close");
    }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.removeTooltip);
  },
  computed: {
    ...mapState("userData", ["currentUser"]),
    header () {
      return [
        this.$t("list_menu.title_upstream"),
        this.$t("list_menu.title_company"),
        this.$t("list_menu.title_downstream"),
        this.$t("list_menu.title_scope_others"),
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.v-dialog__content--active  {
  .content-main {
    background-color: rgba(239, 245, 245, 1);
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
  }
}

.width-100pc {
  max-width: 100%;
  width: 100%;
}

.desktop img {
  width: 20px;
  height: 36px;
}

// .component-scope-4 {
//   display: flex;
//   align-items: center;
//   width: 100%;
// }

.log-confirm_text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.03em;
  color: $goldMid;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.list-emission-close {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 66px;
  height: 62px;
  background: #F7F7F2;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  cursor: pointer;
  &__text {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: $monoBlack;
   }
  .close-active {
    display: none;
  }
  .close {
    display: block;
  }
  &:hover {
    background: $goldLight;
    .close-active {
      display: block;
    }
    .close {
      display: none;
    }
    .list-emission-close__text  {
      color: $monoWhite;
    }
  }
  &:active {
    background: $monoOffWhite;
    border: 2px solid $blueMid;
    .close-active {
      display: none;
    }
    .close {
      display: block;
    }
    .list-emission-close__text  {
      color: $monoBlack;
    }
  }
}
.summary {
  margin-top: 0;
  height: 100%;
  display: grid;
  align-items: center;
}
</style>

<style lang="scss" scoped>
.content-main {
  .head-category-popup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
    background-color: #EFF5F5;
    border-bottom: 1px solid rgba(121, 134, 134, 0.12);
  }
  .emissions-page {
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: center;
    background: #eff5f5;
  }
}

.emissions-page {

  .summary-area {
    margin-top: 0px;

    .summary-area__scope {
      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px 0;

        &.desktop {
          display: none;
        }
      }

      .disabled {
        opacity: 0.3;
      }

      .component-scope-4 {
        display: flex;
        flex-flow: column;
      }
    }

    &.disabled {
      display: none;
    }
  }

  .page-header {
    margin-top: 48px;
    margin-bottom: 48px;
    color: $monoBlack;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.05em;
  }
}

@media (max-width: 1131px) {
  .padding-left-layout {
    padding-left: 0px !important;
  }
  #inspire-mobile .content-main {
    padding: 0 !important;
    min-height: 100vh !important;
  }
}

@media (min-width: 1131px) {
  .emissions-page {
    padding-right: 40px;

    .summary-area {
      margin-top: 0px;

      .summary-area__scope {
        display: flex;

        .disabled {
          opacity: 0.3;
        }

        .arrow {
          padding: 0 16px;

          &.mobile {
            display: none;
          }

          &.desktop {
            display: flex;
          }
        }

        .summary-area__scope-left,
        .summary-area__scope-middle,
        .summary-area__scope-right {
          width: calc((100% - 106px) / 3);
        }

        .summary-area__scope-middle,
        .summary-area__scope-right {
          background-color: $bgLight;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .emissions-page {
    padding: 0 20px 80px;
  }
}

@include desktop {
  .content-main {
    .head-category-popup {
      padding: 16px 40px;
    }
    .emissions-page {
      padding: 0 40px;
      padding-bottom: 48px;
      .summary-area {
        .summary-area__scope {
          .component-scope-4 {
            flex-flow: row;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .head-category-popup {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 2;
    background: #eff5f5;
    width: 100%;
    border-bottom: solid 1px rgba(121, 134, 134, 0.12);;
  }

  .page-header {
    margin-top: 142px !important;
  }
}

.divider {
  margin-bottom: 40px !important;
}
</style>
