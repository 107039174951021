import { getListMasterDb, getListDbCustomize } from '@/api/registerData';
import { mapState } from 'vuex';

// Handle all logic related to the Master-db

export default {
	data() {
		return {
			masterDb: []
		}
	},

	computed: {
		...mapState("userData", ["planType"])
	},

	methods: {
		async getDetailMasterDb(paramData) {
			const { db_types, scope_id, category_id, method_id } = paramData;
			let params = {
				db_types: db_types,
				contractor_id: this.currentUser.user.contractor_id,
				scope_id: scope_id,
				category_id: category_id,
				method_id: method_id,
				status: true,
				is_primary_data: 1
			}
			if (category_id === 15) delete params.is_primary_data
			const masterData = await getListMasterDb(params);
			this.masterDb = masterData?.masterDb;
		},

		async getDataWithDbCustomize(paramData) {
			const { scope_id, category_id } = paramData;
			const response = await getListDbCustomize({
				contractor_id: this.currentUser.user.contractor_id,
				category_id: category_id,
				scope_id: scope_id,
				is_primary_data: 1,
				status: true,
			});
			this.masterDb = response.data.map(item => {
				return {
					...item,
					idType: `${item.id}-${item.type}`
				}
			})
		},

		async getMasterDb(scope, category) {
			if (scope === 1 && [1, 2].includes(this.currentSelectedMethod)) {
				await this.getDetailMasterDb({
					db_types: [1],
					contractor_id: this.currentUser.user.contractor_id,
					scope_id: scope,
					category_id: 0,
					method_id: this.currentSelectedMethod === 1 ? 4 : 1,
					status: true,
				});
			}

			if (scope === 1 && (this.currentSelectedMethod === 3 || this.currentSelectedMethod === 4)) {
				await this.getDetailMasterDb({
					db_types: [3],
					contractor_id: this.currentUser.user.contractor_id,
					scope_id: scope,
					category_id: 0,
					method_id: this.currentSelectedMethod === 3 ? 3 : 2,
					status: true,
				});
			}

			if (scope === 2) {
				await this.getDetailMasterDb({
					db_types: [1, 2],
					contractor_id: this.currentUser.user.contractor_id,
					scope_id: scope,
					category_id: 0,
					method_id: 0,
					status: true,
				})
			}

			if (scope === 3) {
				let methodId = this.currentSelectedMethod;
				if (this.currentSelectedMethod === 1) {
					methodId = 2;
				} else if (this.currentSelectedMethod === 3) {
					methodId = 1;
				} else if (this.currentSelectedMethod === 2) {
					methodId = 3;
				}
				switch (category) {
					case 1:
					case 3:
						await this.getDetailMasterDb({
							db_types: [3],
							contractor_id: this.currentUser.user.contractor_id,
							scope_id: scope,
							category_id: category,
							method_id: 0,
							status: true,
						});
						break;
					case 4:
						if (this.currentSelectedLayer2 === 4 || this.currentSelectedLayer2 === 7) {
							await this.getDetailMasterDb({
								db_types: [1],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: this.currentSelectedLayer2,
								status: true,

							});
						}

						if (this.currentSelectedLayer2 === 5 || this.currentSelectedLayer2 === 6) {
							await this.getDetailMasterDb({
								db_types: [3],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: this.currentSelectedLayer2 === 6 ? 5 : 6,
								status: true,
							});
						}

						if (this.currentSelectedLayer2 === 8) {
							await this.getDetailMasterDb({
								db_types: [2],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: this.currentSelectedLayer2,
								status: true,
							});
						}
						break;
					case 9:
						if (this.currentSelectedLayer2 === 1 || this.currentSelectedLayer2 === 4) {
							await this.getDetailMasterDb({
								db_types: [1],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: this.currentSelectedLayer2,
								status: true,
							});
						}
						if (this.currentSelectedLayer2 === 5) {
							await this.getDetailMasterDb({
								db_types: [2],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: this.currentSelectedLayer2,
								status: true,
							});
						}

						if (this.currentSelectedLayer2 === 2 || this.currentSelectedLayer2 === 3) {
							await this.getDetailMasterDb({
								db_types: [3],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: this.currentSelectedLayer2 === 2 ? 3 : 2,
								status: true,
							});
						}
						break;
					case 6:
						if (this.currentSelectedMethod) {
							if (this.currentSelectedMethod === 1) {
								await this.getDetailMasterDb({
									db_types: [1],
									contractor_id: this.currentUser.user.contractor_id,
									scope_id: scope,
									category_id: category,
									method_id: methodId,
									status: true,
								});
							} else {
								await this.getDetailMasterDb({
									db_types: [3],
									contractor_id: this.currentUser.user.contractor_id,
									scope_id: scope,
									category_id: category,
									method_id: methodId,
									status: true,
								});
							}
						}
						break;
					case 7:
						if (this.currentSelectedMethod === 1) {
							await this.getDetailMasterDb({
								db_types: [1],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: methodId,
								status: true,
							});
						} else {
							await this.getDetailMasterDb({
								db_types: [3],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: methodId,
								status: true,
							});
						}
						break;
					case 10:
						await this.getDetailMasterDb({
							db_types: [1, 2],
							contractor_id: this.currentUser.user.contractor_id,
							scope_id: scope,
							category_id: category,
							method_id: 0,
							status: true,
						});
						break;
					case 5:
					case 12:
						await this.getDetailMasterDb({
							db_types: [3, 4],
							contractor_id: this.currentUser.user.contractor_id,
							scope_id: scope,
							category_id: category,
							method_id: 0,
							status: true,
						});
						break;
					case 13:
						await this.getDetailMasterDb({
							db_types: [1, 2, 3],
							contractor_id: this.currentUser.user.contractor_id,
							scope_id: scope,
							category_id: category,
							method_id: 0,
							status: true,
						});
						break;

					case 11:
						if (this.currentSelectedLayer2 === 1) {
							await this.getDetailMasterDb({
								db_types: [1, 2, 3],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: this.currentSelectedLayer2,
								status: true,
							});
						}

						if (this.currentSelectedLayer2 === 2) {
							await this.getDetailMasterDb({
								db_types: [1],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: this.currentSelectedLayer2,
								status: true,
							});
						}

						if (this.currentSelectedLayer2 === 3) {
							// Waitting for API
							await this.getDataWithDbCustomize({
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: this.currentSelectedLayer2,
								status: true,
							});
						}

						if (this.currentSelectedMethod === 2) {
							await this.getDetailMasterDb({
								db_types: [1, 2],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: 4,
								status: true,
							});
						}
						break;
					case 15:
						if (this.currentSelectedMethod && !this.planType?.is_pcaf)
							await this.getDetailMasterDb({
								db_types: [3],
								contractor_id: this.currentUser.user.contractor_id,
								scope_id: scope,
								category_id: category,
								method_id: 0,
								status: true,
							})
						break;
					default:
						break;
				}
			}
		},
	},
}
