import { mapState } from 'vuex';

// Handle all logic related to the method

export default {
	data() {
		return {
			methodListLayer2: [],
			methodList: [],

			radioDialog: false,
			radioDialogLayer2: false,
			isLoadingData: true,

			methodSize: 1,
			methodSelectedInPopup: null,
			methodLayer2SelectedInPopup: null,
			currentSelectedMethod: null,
			tempCurrentSelectedMethod: null,
			currentSelectedLayer2: null,

			methodLayer2: null,

			labelMethod: this.$t('b_export_report.placeholder_selected_calculation_method')
		}
	},

	computed: {
		...mapState("userData", ["planType"])
	},
	methods: {
		getMethodList(scope, category) {
			this.methodSize = 0;
			this.methodList = [];
			let listMethodName = [];
			let description = [];

			if (scope === 1) {
				this.methodSize = 4;
				listMethodName = [this.$t('register_data.radio_calculated_activities_other_than_transportation'), this.$t('register_data.radio_calculated_by_fuel_method_scope1'), this.$t('register_data.radio_calculated_by_fuel_efficiency_method_scope1'), this.$t('register_data.radio_calculated_by_the_tkm_method_scope1')];
				description = [
          this.$t("register_data.description_activities_other_than_transportation"),
					this.$t("register_data.description_fuel_method"),
					this.$t("register_data.description_fuel_efficiency_method"),
					this.$t("register_data.description_tkm_method"),
				];
			}

			if (scope === 3) {
				if (category === 4) {
					this.methodSize = 3;
					listMethodName = [
						this.$t('register_data.radio_transportation'),
						this.$t('register_data.radio_base'),
						this.$t('register_data.radio_shipper_responsibility_report'),
					];
					description = [
						this.$t('register_data.description_transportation'),
						this.$t('register_data.description_base'),
						this.$t('register_data.description_shipper_responsibility_report'),
					];
				}
				if (category === 6) {
					this.methodSize = 7;
					listMethodName = [
						this.$t('register_data.radio_calculated_by_fuel_method'),
						this.$t('register_data.radio_calculated_by_fuel_efficiency_method'),
						this.$t('register_data.radio_calculated_passenger_kilometer'),
						this.$t('register_data.radio_calculated_payment_amount'), //s3c6 old
						this.$t('register_data.radio_calculated_number_of_accommodation'),
						this.$t('register_data.radio_calculated_number_business_trip_day'),
						this.$t('register_data.radio_calculated_number_of_employees'),
					];
					description = [
						this.$t('register_data.description_calculated_fuel'),
						this.$t('register_data.description_calculated_fuel_efficiency'),
						this.$t('register_data.description_calculated_passenger_kilometer'),
						this.$t('register_data.description_calculated_payment_amount'),
						this.$t('register_data.description_calculated_number_of_accommodation'),
						this.$t('register_data.description_calculated_number_business_trip_day'),
						this.$t('register_data.description_calculated_number_of_employees'),
					];
				}
				if (category === 7) {
					this.methodSize = 5;
					listMethodName = [
						this.$t('register_data.label_calculated_by_fuel_method'),
						this.$t('register_data.radio_calculated_by_fuel_efficiency_method'),
						this.$t('b_register_product.method_calculated_from_a_passenger_kilometer'),
						this.$t('register_data.radio_calculated_payment_amount'),
						this.$t('register_data.radio_calculated_from_the_number_of_employees_and_business_days'),
					];
					description = [
						this.$t('register_data.description_calculated_fuel'),
						this.$t('register_data.description_calculated_fuel_efficiency'),
						this.$t('register_data.description_calculated_passenger_kilometer'),
						this.$t('register_data.description_calculated_payment_amount'),
						this.$t('register_data.description_calculated_number_of_employees'),
					];
				}
				if (category === 15) {
					if (this.planType?.is_pcaf) {
						this.methodSize = 7;
						listMethodName = [
							this.$t('setting_pcaf.pulldown_pcaf_method_listed_stocks_bouds'),
							this.$t('setting_pcaf.pulldown_pcaf_method_bussiness_loans_unlisted_stocks'),
							this.$t('setting_pcaf.pulldown_pcaf_method_project_finance'),
							this.$t('setting_pcaf.pulldown_pcaf_method_commercial_estate'),
							this.$t('setting_pcaf.pulldown_pcaf_method_home_loan'),
							this.$t('setting_pcaf.pulldown_pcaf_method_car_loan'),
							this.$t('setting_pcaf.pulldown_pcaf_method_debt')
						];
					} else {
						this.methodSize = 4;
						listMethodName = [
							this.$t('register_data.radio_stock'),
							this.$t('register_data.method_debt_bond_loan'),
							this.$t('register_data.radio_project_finance'),
							this.$t('register_data.radio_asset_management_customer_service'),
						];
						description = [
							this.$t('register_data.description_stock'),
							this.$t('register_data.description_debt_bond_loan'),
							this.$t('register_data.description_project_finance'),
							this.$t('register_data.description_asset_management_customer_service'),
						];
					}
				}
				if (category === 9) {
					this.methodSize = 2;
					listMethodName = [this.$t('register_data.radio_transportation'), this.$t('register_data.radio_base')];
					description = [
						this.$t('register_data.description_transportation'),
						this.$t('register_data.description_base'),
					];
				}
				if (category === 11) {
					this.methodSize = 2;
					listMethodName = [
						this.$t('register_data.radio_calculated_direct_use_stage'),
						this.$t('register_data.radio_calculated_indirect_use_stage'),
					];
					description = [
						this.$t('register_data.description_calculated_direct_use_stage'),
						this.$t('register_data.description_calculated_indirect_use_stage'),
					];
				}
			}

			for (let index = 1; index <= this.methodSize; index++) {
				let method = {
					text: listMethodName[index - 1],
					value: index,
					id: index,
					name: listMethodName[index - 1],
					description: description[index - 1],
				};
				this.methodList.push(method);
			}
		},

		getMethodListLayer2(scope, category) {
			this.methodListLayer2 = [];
			let listMethodName = [];
			let description = [];
			this.methodSize = 0;

			if (scope === 3 && category === 4 && this.currentSelectedMethod === 1) {
				this.methodSize = 6;
				listMethodName = [
					this.$t('register_data.method_calculated_by_fuel_method_and_shipping_fee'),
					this.$t('register_data.radio_calculated_by_fuel_efficiency_method'),
					this.$t('register_data.radio_calculated_by_the_tkm_method'),
				];
				description = [
					this.$t('register_data.description_fuel_method'),
					this.$t('register_data.description_fuel_efficiency_method'),
					this.$t('register_data.description_tkm_method'),
				];
				for (let index = 4; index <= this.methodSize; index++) {
					let method = {
						text: listMethodName[index - 4],
						value: index,
						id: index,
						name: listMethodName[index - 4],
						description: description[index - 4],
					};
					this.methodListLayer2.push(method);
				}
			} else if (scope === 3 && category === 4 && this.currentSelectedMethod === 2) {
				this.methodSize = 8;
				listMethodName = [this.$t('register_data.radio_fuel'), this.$t('register_data.radio_electricity')];
				description = [this.$t('register_data.description_fuel'), this.$t('register_data.description_electricity')];
				for (let index = 7; index <= this.methodSize; index++) {
					let method = {
						text: listMethodName[index - 7],
						value: index,
						id: index,
						name: listMethodName[index - 7],
						description: description[index - 7],
					};
					this.methodListLayer2.push(method);
				}
			} else if (scope === 3 && category === 9 && this.currentSelectedMethod === 1) {
				this.methodSize = 3;
				listMethodName = [
					this.$t('register_data.radio_fuel_and_shipping'),
					this.$t('register_data.radio_calculated_by_fuel_efficiency_method'),
					this.$t('register_data.radio_calculated_by_the_tkm_method'),
				];
				description = [
					this.$t('register_data.description_fuel_and_shipping'),
					this.$t('register_data.description_fuel_efficiency_method'),
					this.$t('register_data.description_tkm_method'),
				];
				for (let index = 1; index <= this.methodSize; index++) {
					let method = {
						text: listMethodName[index - 1],
						value: index,
						id: index,
						name: listMethodName[index - 1],
						description: description[index - 1],
					};
					this.methodListLayer2.push(method);
				}
			} else if (scope === 3 && category === 9 && this.currentSelectedMethod === 2) {
				this.methodSize = 5;
				listMethodName = [this.$t('register_data.radio_fuel'), this.$t('register_data.radio_electricity')];
				description = [this.$t('register_data.description_fuel'), this.$t('register_data.description_base')];
				for (let index = 4; index <= this.methodSize; index++) {
					let method = {
						text: listMethodName[index - 4],
						value: index,
						id: index,
						name: listMethodName[index - 4],
						description: description[index - 4],
					};
					this.methodListLayer2.push(method);
				}
			} else if (scope === 3 && category === 11 && this.currentSelectedMethod === 1) {
				this.methodSize = 3;
				listMethodName = [
					this.$t('register_data.radio_calculated_engery_using_product'),
					this.$t('register_data.radio_calculated_fuel_feedstock'),
					this.$t('register_data.radio_calculated_containing_product'),
				];
				description = [
					this.$t('register_data.description_calculated_engery_using_product'),
					this.$t('register_data.description_calculated_fuel_feedstock'),
					this.$t('register_data.description_calculated_containing_product'),
				];
				for (let index = 0; index < this.methodSize; index++) {
					let method = {
						text: listMethodName[index],
						value: index + 1,
						id: index + 1,
						name: listMethodName[index],
						description: description[index],
					};
					this.methodListLayer2.push(method);
				}
			}
		},

		getLabelMethodName() {
			let label = this.categorySelected.cateId === 15 ? this.$t('setting_pcaf.label_method_pcaf') : this.$t('primary_data_merge.label_method')
			if (this.methodSelectedInPopup) {
				label = this.methodList.find((item) => item.id === this.methodSelectedInPopup)?.name;
			}
			if (this.methodLayer2SelectedInPopup && this.methodSelectedInPopup) {
				let methodLayer2Name = this.methodListLayer2.find((item) => item.id === this.methodLayer2SelectedInPopup)?.name
				label = `${this.methodList.find((item) => item.id === this.methodSelectedInPopup)?.name} ${ methodLayer2Name ? '・' + methodLayer2Name : ''} `
			}

			return label
		},

		getHeaderFromScope(scope, category, patternId) {
			// set method for get data by workflow id
			if (![4, 6, 7, 9, 11, 15]?.includes(category)) {
				if (scope == 1 && category == 1 && patternId === 1) {
					this.currentSelectedMethod = 2;
				}
				if (scope == 1 && category == 1 && patternId === 2) {
					this.currentSelectedMethod = 4;
				}
				if (scope == 1 && category == 1 && patternId === 3) {
					this.currentSelectedMethod = 3;
				}
				if (scope == 1 && category == 1 && patternId === 4) {
					this.currentSelectedMethod = 1;
				}
			} else if (category === 4) {
				switch (patternId) {
					case 4:
						this.currentSelectedMethod = 1;
						this.currentSelectedLayer2 = 4;
						break;
					case 5:
						this.currentSelectedMethod = 1;
						this.currentSelectedLayer2 = 6;
						break;
					case 6:
						this.currentSelectedMethod = 1;
						this.currentSelectedLayer2 = 5;
						break;
					case 7:
						this.currentSelectedMethod = 2;
						this.currentSelectedLayer2 = 7;
						break;
					case 8:
						this.currentSelectedMethod = 2;
						this.currentSelectedLayer2 = 8;
						break;
					case 2:
						this.currentSelectedMethod = 3;
						this.currentSelectedLayer2 = null;
						break;
					default:
						break;
				}
			} else if (category === 9) {
				if (patternId === 1) {
					this.currentSelectedLayer2 = 1;
					this.currentSelectedMethod = 1;
				}
				else if (patternId === 2) {
					this.currentSelectedLayer2 = 3;
					this.currentSelectedMethod = 1;
				} else if (patternId === 3) {
					this.currentSelectedMethod = 1;
					this.currentSelectedLayer2 = 2;
				} else if (patternId === 4) {
					this.currentSelectedMethod = 2;
					this.currentSelectedLayer2 = patternId;
				} else if (patternId === 5) {
					this.currentSelectedMethod = 2;
					this.currentSelectedLayer2 = patternId;
				}

			} else if (category === 11) {
				if (patternId === 4) {
					this.currentSelectedMethod = 2;
					this.currentSelectedLayer2 = null;
				} else {
					this.currentSelectedLayer2 = patternId;
					this.currentSelectedMethod = 1;
				}
			} else if (category === 15) {
				this.currentSelectedMethod = this.planType?.is_pcaf ? patternId - 4 : patternId
				this.currentSelectedLayer2 = null
			}

			else if (category === 6 || category === 7) {
				if (patternId === 2) {
					this.currentSelectedMethod = 1
				}
				else if (patternId === 3) {
					this.currentSelectedMethod = 2
				}
				else if (patternId === 1) {
					this.currentSelectedMethod = 3
				} else {
					this.currentSelectedMethod = patternId
				}
			}
			else if (category === 11) {
				if (patternId === 4) {
					this.currentSelectedMethod = 2;
					this.currentSelectedLayer2 = null;
				} else {
					this.currentSelectedLayer2 = patternId;
					this.currentSelectedMethod = 1;
				}
			}
		},


		getPatternIdCurrent() {
			let scope = this.formData.scope.scope
			let category = this.formData.scope.cateId
			if (scope !== 3) {
				this.patternId = this.currentSelectedMethod ? this.setPatternId(category, scope, this.currentSelectedMethod, this.currentSelectedLayer2) : 1;
			} else {
				if (category === 4) {
					if (this.currentSelectedMethod === 3) {
						this.patternId = 2;
					}
					if (this.currentSelectedLayer2 !== null) {
						this.patternId = this.currentSelectedLayer2;
					}
				}
				else if ([6, 7].includes(category)) {
					this.patternId = this.method;
				}
				else if (category === 9) {
					this.patternId = this.currentSelectedLayer2;
				} else if (category === 11) {
					if (this.currentSelectedMethod === 2) {
						this.patternId = 4;
					}
					if (this.currentSelectedLayer2 !== null) {
						this.patternId = this.currentSelectedLayer2;
					}
				} else if (category === 15) {
					this.patternId = this.currentSelectedMethod
				} else {
					this.patternId = 1;
				}
				// TODO: Change display order
				if (scope === 1 || scope === 3) {
					this.patternId = this.setPatternId(category, scope, this.currentSelectedMethod, this.currentSelectedLayer2);
				}
			}
		},

		setPatternId(category, scope, method, methodLayer2) {
			if (category === 1 && scope === 1) {
				if (method === 2) {
          return 1;
        } else if (method === 3) {
          return 3;
        } else if (method === 4) {
          return 2;
        }
        return 4;
			} else if (scope === 3) {
				if (category === 4) {
					if (method === 1) {
						if (methodLayer2 === 5) {
							return 6;
						} else if (methodLayer2 === 6) {
							return 5;
						}
					}
					if (method === 3) {
						return 2
					}
				}
				if (category === 6) {
					if (method === 1) {
						return 2;
					} else if (method === 2) {
						return 3;
					} else if (method === 3) {
						return 1;
					}
				}
				if (category === 7) {
					if (method === 1) {
						return 2;
					} else if (method === 2) {
						return 3;
					} else if (method === 3) {
						return 1;
					}
				}
				if (category === 9) {
					if (method === 1) {
						if (methodLayer2 === 2) {
							return 3;
						} else if (methodLayer2 === 3) {
							return 2;
						}
					}
				}
				if (category === 11) {
					if (method === 2) {
						return 4
					}
				}
				if (category === 15) {
					return this.planType?.is_pcaf ? method + 4 : method
				}
			}
			let patternId = this.patternId || methodLayer2 || method;

			return patternId;
		},
	},
}