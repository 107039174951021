import { SCOPE, CATEGORY } from '@/constants/registerData';
import { DESCRIPTIONS } from '@/constants/descriptions';

import i18n from '@/lang/i18n';

export const getLabelScopeCategory = (scopeAndCategory) => {
  if (scopeAndCategory.scope > SCOPE.SCOPE_2) {
    if (scopeAndCategory.cateId === CATEGORY.CATEGORY_16) {
      return `${i18n.t('dashboard_main.label_scope')} ${scopeAndCategory.scope} > ` +
        DESCRIPTIONS['scope' + scopeAndCategory.scope]['category' + scopeAndCategory.cateId]['title'];
    } else if (scopeAndCategory.scope === SCOPE.SCOPE_4) {
      return `${i18n.t('register_data.title_emission_scope4')} > ` +
        DESCRIPTIONS['scope' + scopeAndCategory.scope]['category' + scopeAndCategory.cateId]['title'];
    } else {
      return `${i18n.t('dashboard_main.label_scope')} ${scopeAndCategory.scope} > ${scopeAndCategory.cateId}. ` +
        DESCRIPTIONS['scope' + scopeAndCategory.scope]['category' + scopeAndCategory.cateId]['title'];
    }
  } else {
    return `${i18n.t('dashboard_main.label_scope')} ${scopeAndCategory.scope} > ` +
      DESCRIPTIONS['scope' + scopeAndCategory.scope]['category' + scopeAndCategory.cateId]['title'];
  }
}