import request from './base/request';
const PRIMARY_DATA_MERGE = '/primary-data';

export const getListFormPrimaryDataApi = () => {
  return request
    .get(PRIMARY_DATA_MERGE)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const createFormPrimary = (data) => {
  return request
    .post('primary-data', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getFormPrimaryById = (id) => {
  return request
    .get(PRIMARY_DATA_MERGE + `/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateFormPrimaryData = (data) => {
  return request
    .put(PRIMARY_DATA_MERGE, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateFormPrimaryById = (id, data) => {
  return request
    .put(PRIMARY_DATA_MERGE + `/${id}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};
export const updateStatusDataWorkFlowDataMerge = (data) => {
  return request
    .put('linked-primary-data', data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

