import request from './base/request';
import { prepareCancelRequest, prepareFormData, handleActionCancelRequest, handleFinallyCancelRequest } from '@/api/base/prepareRequest';
const PCAF = 'pcaf';
let requests = {};

export const getDashboardApi = (params) => {
  return prepareApiData({
    payload: params,
    url: `dashboard/${PCAF}`,
    key: 'getDashboardApi',
  })
};

export const getPieChartApi = (params) => {
  return prepareApiData({
    payload: params,
    url: `dashboard/${PCAF}/detail`,
    key: 'getPieChartApi',
  })
};

export const getTrendLineChartApi = (params) => {
  return prepareApiData({
    payload: params,
    url: `dashboard/${PCAF}/trends-detail`,
    key: 'getTrendLineChartApi',
  })
};

export const getTrendsSectorApi = (params) => {
  return prepareApiData({
    payload: params,
    url: `dashboard/${PCAF}/trends-sector`,
    key: 'getTrendsSectorApi',
  })
};

export const getChangeScoreChartApi = (params) => {
  return prepareApiData({
    payload: params,
    url: `dashboard/${PCAF}/change-score`,
    key: 'getChangeScoreChartApi',
  })
};

export const getPieIndustryChartApi = (params) => {
  return prepareApiData({
    payload: params,
    url: `dashboard/${PCAF}/detail-sector`,
    key: 'getPieIndustryChartApi',
  })
};

export const getDataTypeApi = (method) => {
  const urlDataType = method ? `type-data-pcafs?scope=3&category=15&method=${method}` : `type-data-pcafs`
  return request
    .get(urlDataType)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    })
};

const prepareApiData = (params) => {
  const { payload, url, key } = params;

  const data = prepareCancelRequest(requests, key);
  const { cancelTokenSource, config } = data;
  
  handleActionCancelRequest(requests, key);
  requests = data.requests;
  
  const body = prepareFormData(payload);
  return request
    .post(url, body, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      handleFinallyCancelRequest(requests, key, cancelTokenSource);
    });
}
