import i18n from '@/lang/i18n.js'
export const PALETTE = ['#327998', '#C24E4B', '#89B473', '#E0A329', '#9F62BD', '#ACAE4E'];
export const S3C15_SETTING_TEXT= i18n.t("other_setting.label_calc_scope_3_category_15");
export const ASSET_ITEMS = [
  {
    id: 5,
    name: i18n.t("dashboard_main.label_listed_stocks_bonds"), 
  },
  {
    id: 6,
    name: i18n.t("dashboard_main.label_business_loan_unlisted_stocks"), 
  },
  {
    id: 7,
    name: i18n.t("dashboard_main.label_project_finance"), 
  },
  {
    id: 8,
    name: i18n.t("dashboard_main.label_commercial_real_estate"), 
  },
  {
    id: 9,
    name: i18n.t("dashboard_main.label_housing_loan"), 
  },
  {
    id: 10,
    name: i18n.t("dashboard_main.label_auto_loan"), 
  },
  {
    id: 11,
    name: i18n.t("dashboard_main.label_pcaf_method_debt"), 
  },
];
export const HALF_YEAR = [
  {
    id: 1,
    value: i18n.t("dashboard_main.label_first_half_chart_title"),
    detail_text: i18n.t("dashboard_main.label_first_half_detail"),
  },
  {
    id: 2,
    value: i18n.t("dashboard_main.label_end_half_chart_title"),
    detail_text: i18n.t("dashboard_main.label_end_half_detail"),
  }
]
export const QUARTER_YEAR = [
  {
    id: 1,
    value: '1Q',
    detail_text: i18n.t("dashboard_main.label_first_quarter_detail"),
  },
  {
    id: 2,
    value: '2Q',
    detail_text: i18n.t("dashboard_main.label_second_quarter_detail"),
  },
  {
    id: 3,
    value: '3Q',
    detail_text: i18n.t("dashboard_main.label_third_quarter_detail"),
  },
  {
    id: 4,
    value: '4Q',
    detail_text: i18n.t("dashboard_main.label_fourth_quarter_detail"),
  }
]

export const HALF_YEAR_ICP = [
  {
    id: 1,
    value: i18n.t("icp_target_integration.label_dashboard_first_half_of_fiscal_year"),
  },
  {
    id: 2,
    value: i18n.t("icp_target_integration.label_dashboard_second_half_of_fiscal_year"),
  }
]
export const QUARTER_YEAR_ICP = [
  {
    id: 1,
    value: i18n.t("icp_target_integration.label_dashboard_quarter_year_1"),
  },
  {
    id: 2,
    value: i18n.t("icp_target_integration.label_dashboard_quarter_year_2"),
  },
  {
    id: 3,
    value: i18n.t("icp_target_integration.label_dashboard_quarter_year_3"),
  },
  {
    id: 4,
    value: i18n.t("icp_target_integration.label_dashboard_quarter_year_4"),
  }
]

export const PCAF_CATEGORY_TYPE_METHOD_1 = [
  {
    id: 1,
    name: i18n.t("pcaf_update.listed_stocks"), 
  },
  {
    id: 2,
    name: i18n.t("pcaf_update.corporate_bond"), 
  }
]

export const PCAF_CATEGORY_TYPE_METHOD_2 = [
  {
    id: 1,
    name: i18n.t("pcaf_update.listed_company_loan")
  },
  {
    id: 2,
    name: i18n.t("pcaf_update.unlisted_company_loan"), 
  }
]
