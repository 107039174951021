
// Name input: 燃料
export const Fuel = {
  scope3Catagory4Method6: [
    {
      value: '軽貨物車',
      key: '軽貨物車'
    },
    {
      value: '小型貨物車',
      key: '小型貨物車'
    },
    {
      value: '普通貨物車',
      key: '普通貨物車'
    },
    {
      value: '船舶',
      key: '船舶'
    },
    {
      value: '鉄道',
      key: '鉄道'
    },
    {
      value: '航空',
      key: '航空'
    }
  ],
  scope3Catagory9Method3: [
    {
      value: 'ガソリン',
      key: 'ガソリン'
    },
    {
      value: '軽油',
      key: '軽油'
    },
    {
      value: 'その他',
      key: 'その他'
    }
  ]
}

// Name input: 車種
export const CarModel = {
  scope3Catagory4Method6: [
    {
      value: 'ガソリン',
      key: 'ガソリン'
    },
    {
      value: '軽油',
      key: '軽油'
    },
    {
      value: 'その他',
      key: 'その他'
    }
  ],
  scope3Catagory9Method3: [
    {
      value: '軽貨物車',
      key: '軽貨物車'
    },
    {
      value: '小型貨物車',
      key: '小型貨物車'
    },
    {
      value: '普通貨物車',
      key: '普通貨物車'
    },
    {
      value: '船舶',
      key: '船舶'
    },
    {
      value: '鉄道',
      key: '鉄道'
    },
    {
      value: '航空',
      key: '航空'
    }
  ],
}

// Name input: 処理方法
export const ProcessingMethods = [
  { value: '焼却', key: '焼却' },
  { value: '埋立', key: '埋立' },
  { value: 'リサイクル', key: 'リサイクル' },
  { value: '不明', key: '不明' },
]

// Name input: 分類
export const Classification = [
  { value: 1, key: '産業廃棄物' },
  { value: 2, key: '一般廃棄物' },
  { value: 3, key: '不明' },
]